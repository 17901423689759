import * as React from "react";
import Parallax from 'react-rellax'
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, loadImage } from '../gsap/timeline';
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

const InfoImage = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ imageType, pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
    
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "ExtendedRow") {
                detailItems.push(detail.fields);
            }
        }
    
        return (
            <Observer {...options}>
                <div className="block expertise gsap">
                    {
                        detailItems.map( (detail) => { 
                            return  <div key={ shortid.generate() } className="container remove--relative">
                                        <div className="expertise__left section--padding gsap-timeline">
                                        <Parallax speed={0.2}>
                                                <p>
                                                    { detail.description }
                                                </p>
                                            </Parallax>
                                        </div><div className="expertise__right">
                                            <picture data-alt="test">
                                                <source srcSet={ `${detail.images[0].fields.file.url}?w=1680&h=865&fm=${ imageType }&q=50` } type="image/jpg" />
                                            </picture>
                                        </div>
                                    </div>
                        })
                    }
                </div>
     
            </Observer>
        )
    }
});

export default React.memo(InfoImage);