import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock } from '../gsap/timeline';
import FuelItem from "./FuelItem";
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        unobserve();
    }
}

const FuelType = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ title, description, fuels }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
    
        const fuelsItems = [];
    
        for (let item of Object.keys(fuels)) {
            let fuel = fuels[item];
            fuelsItems.push(fuel.fields);
        }
    
        return (
            <Observer {...options}>
                <div className="block requirements block--padding gsap">
                    <div className="container">
                        <h2 className="animate--title text-center mb-50 exluded--title">
                            <span>{ title }</span>
                        </h2>
                        <p className="requirements__title gsap-timeline">{ description }</p>
                        <div className="row requirements__items remove--side-margins">
                            {
                                fuelsItems.map( (fuel) => { 
                                    return <FuelItem key={ shortid.generate() } title={ fuel.title } description={ fuel.description }></FuelItem>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
});

export default React.memo(FuelType);