import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { gsapBlock, tweenMaxAnimateOnce } from '../gsap/timeline';
import { asyncComponent } from 'react-async-component';

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        unobserve();
    }
}

const ShortInfo = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ buttons, description }) => {
        const options = {
            onChange: handleIntersection,
            root: '#scrolling-container',
            rootMargin: '0% 0% -25%'
        };
    
        return (
            <Observer {...options}>
                <div className="block history gsap">
                    <div className={ (buttons) ? `history__content history__apps` : `history__content` }>
                        <div className="container">
                            <p className="gsap-timeline">{ description }</p>
                            {
                                (buttons) ?             
                                    <div className="history__links">
                                        <div className="row">
                                            <a href="https://play.google.com/store/apps/details?id=com.frakton.ex_fis&hl=en" className="google__play col-12 col-lg-4 gsap-timeline" target="_blank" rel="noopener noreferrer">          
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23.152" height="26.744" viewBox="0 0 33.152 36.744">
                                                    <path d="M42.113,14.865,17.039.532A4.039,4.039,0,0,0,11,4.039V32.7a4.039,4.039,0,0,0,6.043,3.507L42.112,21.879a4.039,4.039,0,0,0,0-7.014ZM31.247,11.476l-3.443,4.8L19.57,4.8ZM14.876,34.28a1.576,1.576,0,0,1-.64-.2,1.594,1.594,0,0,1-.792-1.375V4.039A1.582,1.582,0,0,1,14.874,2.46L26.3,18.372Zm4.694-2.337L27.8,20.472l3.443,4.8ZM40.9,19.752l-7.512,4.294-4.073-5.674L33.385,12.7,40.9,16.992a1.589,1.589,0,0,1,0,2.76Z" transform="translate(-10.995)" fill="#0f4757"/>
                                                </svg>
                                                <p>Get it on Google Play</p>
                                            </a>
                                            <a href="https://apps.apple.com/us/app/exfis/id1476712703" className="app__store col-12 col-lg-4 gsap-timeline" target="_blank" rel="noopener noreferrer">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.455" height="28.322" viewBox="0 0 31.455 38.322">
                                                    <path d="M76.317,26.231a6.571,6.571,0,0,1-3.67-6.078,7.038,7.038,0,0,1,3.363-5.806l.025-.019a1.117,1.117,0,0,0,.257-1.51,9.6,9.6,0,0,0-8.012-4.04h-.005a8.753,8.753,0,0,0-1.719.179q.3-.241.583-.514A9.819,9.819,0,0,0,69.97,1.085,1.117,1.117,0,0,0,68.854,0a8.5,8.5,0,0,0-6.029,3.168A8.253,8.253,0,0,0,60.647,9.64a1.108,1.108,0,0,0,.193.479c-.3-.1-.613-.216-.924-.33a11.538,11.538,0,0,0-3.777-.929,10.817,10.817,0,0,0-5.124,1.6C48.787,11.846,46.13,14.729,46.13,20.6a26.166,26.166,0,0,0,3.04,10.892c1.276,2.462,3.923,6.594,7.323,6.613h.036c2.479,0,4.533-1.464,5.93-1.464,2.386,0,3.235,1.48,5.816,1.48,2.313,0,4.555-1.757,6.663-5.222a30.071,30.071,0,0,0,1.744-3.363c.7-1.585.7-1.8.7-1.951C77.385,26.873,76.842,26.546,76.317,26.231Zm-11.8-21.6a7.3,7.3,0,0,1,3.164-2.2A7.313,7.313,0,0,1,65.586,6.84,5.371,5.371,0,0,1,62.828,8.3,6.621,6.621,0,0,1,64.515,4.627Zm8.407,27.288c-1.593,2.563-3.244,3.974-4.648,3.974-1.931,0-2.834-1.48-5.816-1.48-2.012,0-3.931,1.474-5.954,1.464-1.578-.009-3.629-2.081-5.353-5.408A23.8,23.8,0,0,1,48.363,20.6c0-3.855,1.267-6.614,3.765-8.2a8.666,8.666,0,0,1,4.018-1.306c1.743,0,4.163,1.547,5.926,1.547,1.587,0,3.713-1.629,6.211-1.629h.041A7.536,7.536,0,0,1,73.8,13.248a8.921,8.921,0,0,0,1.116,14.745A27.674,27.674,0,0,1,72.922,31.915Z" transform="translate(-46.03 0.1)" fill="#0f4757" stroke="#0f4757" strokeWidth="0.2"/>
                                                </svg>
                                                <p>Download on App Store</p>
                                            </a>
                                        </div>
                                    </div> : null
                            }                        
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
})  

export default React.memo(ShortInfo);