import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, loadImage } from '../gsap/timeline';
import Swiper from 'react-id-swiper';
import { asyncComponent } from 'react-async-component';

import ProcessIdeologyItem from './ProcessIdeologyItem';

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}


const ProcessIdeology = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ description, processes }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
    
        const processItems = [];
    
        for (let item of Object.keys(processes)) {
            let process = processes[item];
            processItems.push(process.fields);
        }
    
        const params = {
            slidesPerView: 2,
            spaceBetween: 20,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
              },
            breakpoints: {
                768: {
                  slidesPerView: 4,
                  spaceBetween: 30,  
                },
              },
            containerClass: 'swiper-container__quality'
          }
    
        return (
            <Observer {...options}>
                <div className="block qualities block--padding qualities--quality gsap">
                    <div className="container">
                        <div className="row qualities__content remove--side-margins">
                            <p className="services__description gsap-timeline pb-50"> { description } </p>
                                <Swiper {...params}>
                                {
                                    processItems.map( (process, i) => { 
                                        return <ProcessIdeologyItem key={ i } title={ process.title } image={ process.icon }></ProcessIdeologyItem>
                                    })
                                }
                                </Swiper>
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
})

export default React.memo(ProcessIdeology)