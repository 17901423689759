import * as React from "react";
  
const PageDetailsText = function PageDetailsText({ title, description }) {
    return (
        <div className="statistic__delivery statistic__delivery--retail"> 
            <h3 className="gsap-timeline">{ title }</h3>
            <p className="gsap-timeline"> { description }</p>
        </div>
    )
}

export default React.memo(PageDetailsText);