import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, loadImage } from '../gsap/timeline';
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}
  
const QuoteImageCenter = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ imageType, quoteItem, pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
    
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "Row" && item == quoteItem) {
                detailItems.push(detail.fields);
            }
        }
    
        return (
            <Observer {...options}>
                <div className=" post post--application post--16-9">
                    {
                        detailItems.map( (detail) => { 
                            return <div key={ shortid.generate() } className="block height gsap">
                                        <div className="container height">
                                            <div className="post__items post__items_half">
                                                <h2 className="gsap-timeline"> { detail.title }</h2>
                                                <p className="pb-50 gsap-timeline">
                                                    { detail.description }
                                                </p>
                                                {
                                                    detail.externalLink ?  <a className="post__button" href={ detail.externalLink }>Read More</a> : null
                                                }
                                            </div>
                                            <picture className="quote-image-center" data-alt="application">
                                                <source srcSet={ `${detail.images[0].fields.file.url}?w=1680&h=865&fm=${ imageType }&q=50` } type="image/svg" />
                                            </picture>
                                        </div>
                                    </div>   
                        })   
                    }
                </div>
            </Observer>
        )
    }
});

export default React.memo(QuoteImageCenter);