import * as React from "react";
  
export default function ProcessInfoItem({ title, image }) {
    return (
        <div className="col-12 col-md-4 col-lg-4 services__item gsap-timeline">
            <picture data-alt="search-picture">
                <source srcSet={ image.fields.file.url } type="image/svg" />
            </picture>
            <p>{ title }</p>
        </div>
    )
}