import React, { PureComponent } from 'react'
import Headline from '../components/Headline';
import Footer from '../components/Footer';
import Quote from '../components/Quote';
import Statistics from '../components/Statistics';
import Map from '../components/Map';
import {Helmet} from 'react-helmet';
import { checkBrowser } from '../gsap/timeline';


import { RoomContext } from '../context';
import Loading from '../components/Loading';

const Trading = class Trading extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            slug: this.props.match.url,
            imageType: 'webp'
        }
    }

    componentWillReceiveProps(nextProps){
        if (nextProps.slug !== this.props.slug) {
            this.setState({ slug: nextProps.slug })
        }
    }

    componentDidMount() {
        const isSafari = checkBrowser();
        if(isSafari) {
            this.setState({ imageType: 'jpg' })
        }
    }

    static contextType = RoomContext;

    render() {
        const { getSinglePage, loading } = this.context;
        const value = getSinglePage(this.state.slug);

        return (
            <React.Fragment>
                { loading ? <Loading></Loading> : null }
                <Helmet>
                    <title>{ `EXFIS | ${ value.name }` }</title>
                    <link rel="canonical" href={ window.location.href }></link>
                    <meta name="description" content={ value.description?.content[0].content[0].value  } />
                    <meta property="og:title" content={ `EXFIS | ${ value.name }` } />
                    <meta property="og:description" content={ value.description?.content[0].content[0].value } />
                    <meta property="og:url" content={ window.location.href } />
                    <meta property="og:image" content={ value.headline?.fields.image.fields.file.url } />
                    <meta property="og:image:secure_url" content={ value.headline?.fields.image.fields.file.url } />
                    <meta property="og:image:width" content="1820" />
                    <meta property="og:image:height" content="984" />                            
                </Helmet>   
                <Headline title={ value.headline?.fields.title } image={ `${value.headline?.fields.image.fields.file.url}?w=1680&h=865&fm=${ this.state.imageType }&q=50` }></Headline>
                <Statistics type='list' name={ value.name } title={ value.title } description={ value.description?.content[0].content[0].value } 
                pageDetails={{ ...value?.pageDetails }}></Statistics>
                <Quote quoteItem={ 0 } imageType={ this.state.imageType } pageDetails={{ ...value?.pageDetails }}></Quote>
                <Map processes={{ ...value?.processes }}></Map>
                <Footer></Footer>
            </React.Fragment>
        )
    }
}

export default React.memo(Trading);