import * as React from "react";
  
const PageDetails = function PageDetails({ image, counter, title, description }) {
    return (
        <div className={ (counter === 0) ? `statistic__top section--padding statistic__top--aviation gsap`
        : `statistic__bottom section--padding statistic__bottom--aviation gsap` }>
            <>
                <div className="absolute-center">
                    <p className="statistic__number animate--title">
                        <span>{ title }</span>
                    </p>
                    <p className="statistic__text gsap-timeline">{ description }</p>
                </div>
                <picture className="gsap-timeline" data-alt="fuel">
                    <source srcSet={ image } type="image/webp" />
                </picture>
            </>
        </div>
    )
}

export default React.memo(PageDetails);
