import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, loadImage } from '../gsap/timeline';
import { asyncComponent } from 'react-async-component';

import ProcessInfoItem from './ProcessInfoItem';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

const ProccessInfo = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ description, processes }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
    
        const processItems = [];
    
        for (let item of Object.keys(processes)) {
            let process = processes[item];
            processItems.push(process.fields);
        }
    
        return (
            <Observer {...options}>
                <div className="block services block--padding gsap">
                    <div className="container">
                        <div className="services__text">
                            <p className="services__description gsap-timeline">{ description }</p>
                        </div>
                        <div className="row">
                        {
                            processItems.map( (process) => { 
                                return <ProcessInfoItem key={ shortid.generate() } title={ process.title } image={ process.icon }></ProcessInfoItem>
                            })
                        }
                        </div>
                    </div>
                    <div className="main__border gsap--timeline">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>                
            </Observer>
        )
    }
});

export default React.memo(ProccessInfo);