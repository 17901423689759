import React, { PureComponent } from "react";
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock } from '../gsap/timeline';

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        unobserve();
    }
}
  
const Contact = class Contact extends PureComponent {
    constructor(props) {
        super(props);
        const recaptchaRef = React.createRef();
        const showMessage = {
            message: null,
            errorClass: null
        }

        this.state = {
            fullname: '',
            company: '',
            email: '',
            message: '',
            captcha: recaptchaRef,
            loading: false,
            showMessage: showMessage
        };
    
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        loadReCaptcha("6LduQt8UAAAAAIFmYcb7VxcBG3We1Mmo4ntSY7oE");
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
    }

    verifyCallback = (recaptchaToken) => {
        this.setState({
            captcha: recaptchaToken 
        })
    }

    handleSubmit(event){
        const postData = this.state;
        const request = new XMLHttpRequest();

        const isAlbanian = window.location.pathname.includes('/sq') ? true : false;

        const responseMessages = {
            success: (isAlbanian) ? 'Emaili u dërgua me sukses' : 'Email sent successfully',
            error: (isAlbanian) ? 'Emaili nuk mund të dërgohej' : 'Email could not be sent',
        }

        request.onreadystatechange = () => {
            if (request.readyState === 4) {
                try {
                    const response = JSON.parse(request.response);

                    this.state.showMessage.message = (response.success) ? responseMessages['success'] : responseMessages['error'];
                    this.state.showMessage.errorClass = (response.success) ? 'success' : 'error';

                    this.setState({
                        loading: false,
                        showMessage: this.state.showMessage
                    })
                }catch (e) {
                    this.state.showMessage.message = responseMessages['error'];
                    this.state.showMessage.errorClass = 'error';

                    this.setState({
                        loading: false,
                        showMessage: this.state.showMessage
                    })
                }
            }
        };
        this.setState({
            loading: true
        })
        request.open('POST', '/frm-contact/sendMail.php', true);
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.send(JSON.stringify(postData));
        event.preventDefault();
    }

    render() {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };

        const isAlbanian = window.location.pathname.includes('/sq') ? true : false;
        const buttonSumbitMessage = isAlbanian ? 'Dërgo' : 'Send Now';

        return (
            <Observer {...options}>
                <div className="block form gsap">
                    <div className="container">
                        <p className="services__description pb-50 gsap-timeline">Ex Fis Core Ideology
                        Building upon core values of
                        </p>
                        <div className="form__send">
                            <form method="POST" onSubmit={this.handleSubmit}>
                                <div className="row remove--side-margins">
                                    <input value={this.state.fullname} onChange={this.handleInputChange} name="fullname" className="col-12 col-lg-5 margin--right-3 gsap-timeline" type="text" placeholder={ isAlbanian ? 'Emri dhe mbiemri' : 'Full Name' } maxLength="40" required />
                                    <input value={this.state.company} onChange={this.handleInputChange} name="company"  className="col-12 col-lg-5 gsap-timeline" type="text" placeholder={ isAlbanian ? 'Kompania' : 'Company' } maxLength="40" />
                                    <input value={this.state.email} onChange={this.handleInputChange} name="email"  className="col-12 col-lg-5 margin--right-3 gsap-timeline" type="email" placeholder={ isAlbanian ? 'Emaili' : 'Email Address' } maxLength="40" required /> 
                                    <input value={this.state.message} onChange={this.handleInputChange} name="message" className="col-12 col-lg-5 gsap-timeline" type="text" placeholder={ isAlbanian ? 'Mesazhi' : 'Message' } maxLength="100" required/>
                                    <button className="btn__send gsap-timeline">
                                        { this.state.loading ? <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div> : buttonSumbitMessage }
                                    </button>
                                    <p className={ `showMessage ${this.state.showMessage.errorClass}` }>{ this.state.showMessage.message }</p>
                                    <div className="block-recaptcha gsap-timeline">
                                        <ReCaptcha
                                            sitekey="6LduQt8UAAAAAIFmYcb7VxcBG3We1Mmo4ntSY7oE"
                                            action='action_name'
                                            verifyCallback={this.verifyCallback}
                                        />
                                    </div>
                                </div>   
                            </form>
                        </div>
                    </div>
                </div>     
            </Observer>
        )
    }
}

export default React.memo(Contact);