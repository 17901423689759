import React, { PureComponent } from 'react';
import Headline from '../components/Headline';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';

import { RoomContext } from '../context';
import Loading from '../components/Loading';

const Error = class Error extends PureComponent {
    
    static contextType = RoomContext;

    render() {
        const { loading } = this.context;

        return (
            <React.Fragment>
                { loading ? <Loading></Loading> : null }
                <Helmet>
                    <title>{ `EXFIS | Error` }</title>
                </Helmet>  
                <Headline title="Error 404"></Headline>
                <Footer></Footer>
            </React.Fragment>
        )
    }
}

export default React.memo(Error);