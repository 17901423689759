
import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { gsapBlock, tweenMaxAnimateOnce } from '../gsap/timeline';
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target);
        unobserve();
    }
}

const LongMore = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ pagePolicy }) => {
        const options = {
            onChange: handleIntersection,
            root: '#scrolling-container',
            rootMargin: '0% 0% -25%'
        };
    
        const pagePolicyItems = [];
    
        for (let item of Object.keys(pagePolicy)) {
            let pagePolicyItem = pagePolicy[item];
            pagePolicyItem.content.forEach(element => {
                var policyItem = {
                    mark: element.marks[0] ? element.marks[0].type : null , 
                    value: element.value
                }
                pagePolicyItems.push(policyItem);
            });
            
        }
    
        return (
            <Observer {...options}>
                <div className="block read block--padding gsap">
                    <div className="container">
                        <div className="read__content">
                            {
                                pagePolicyItems.map( (item) => { 
                                    return <p className="gsap-timeline read__text" key={ shortid.generate() }> { item.mark ? <b>{item.value}</b> :item.value } </p>
                                })
                            }                                        
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
});

export default React.memo(LongMore);