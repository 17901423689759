import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, loadImage } from '../gsap/timeline';
import MapImage from '../assets/images/ship-map.svg';
import Swiper from 'react-id-swiper';
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

const params = {
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    containerClass: 'swiper-container__ship'
}

const Map = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ processes }) => {
        const options = {
            onChange: handleIntersection,
            root: '#scrolling-container',
            rootMargin: '0% 0% -15%'
        };
    
        const processItems = [];
    
        for (let item of Object.keys(processes)) {
            let process = processes[item];
            processItems.push(process.fields);
        }
    
        return (
            <Observer {...options}> 
                <div className="block ship block--padding gsap">
                    <div className="container">
                        <div className="swiper-container__ship">
                            <Swiper {...params}> 
                                {
                                    processItems.map( (process) => { 
                                        return <div key={ shortid.generate() } className="swiper-slide"><p> { process.description } </p></div>
                                    })
                                }
                            </Swiper>
                        </div>
                        <div className="ship__map">
                            <picture className="gsap-timeline">
                                <source srcSet={ MapImage } type="image/svg" />
                            </picture>
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
});

export default React.memo(Map);