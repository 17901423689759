import * as React from "react";
  
const PageDetailsList = function PageDetailsList({ counter, title, description }) {
    return (
        <div className={ (counter === 0) ? `fuel__type remove--padding-top gsap-timeline` : `fuel__type gsap-timeline` }>
            <h3 className="animate--title text--uppercase" data-rellax-speed="2" data-rellax-max="10">
            { title }
            </h3>
            <p className="text--uppercase">{ description }</p>
        </div>
    )
}

export default React.memo(PageDetailsList);