import React, { Component } from "react";
import client from './Contentful';
import ReactGA from 'react-ga';

const RoomContext = React.createContext();

class RoomProvider extends Component {
    state = {
        items: [],
        loading: true,
        mounted: false
    }

    async getData() {
        try {
            let pathname = window.location.pathname;
            const isAlbanian = pathname.includes('/sq') ? 'sq' : 'en-US';

            let response = await client.getEntries({
                content_type: 'pages',
                locale: isAlbanian
            });
            let contentfulItems = response.items;
            this.setState({
                contentfulItems, mounted: true
            })

            setTimeout(() => {
                this.setState({
                    loading: false,
                })
            }, 500);
        }catch(error) {
            console.log(error);
        }
    }

    getSinglePage = slug => {
        const language = {
            'sq': 'ballina',
            '': 'home'
        }
        let isAlbanian = slug.includes('/sq/');

        if( isAlbanian ) {
            slug = slug.replace("/sq","");
        }

        let pathname = slug.replace(/\//g,'');
        let items = this.state.contentfulItems;

        pathname = language[pathname] ? language[pathname] : pathname;
        
        if(items) {
            ReactGA.pageview(pathname);

            const item = items.find(item => item.fields.slug === pathname);
            return item.fields;
        }
        return slug;
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        if( this.state.mounted ) { 
            return <RoomContext.Provider value={{ ...this.state, getSinglePage: this.getSinglePage }}>
                { this.props.children }
            </RoomContext.Provider>;
        }

        return null;
    }
}

const RoomConsumer = RoomContext.Consumer;

export { RoomProvider, RoomConsumer, RoomContext }