import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { gsapBlock, loadImage, tweenMaxAnimateOnce } from '../gsap/timeline';
import Swiper from 'react-id-swiper';
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

const SwipperImageText = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            root: '#scrolling-container',
            rootMargin: '0% 0% -25%'
        };
    
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "Small Detail" && item == 0) {
                detailItems.push(detail.fields);
            }
        }
        
        const params = {
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
              clickable: true
            },
            spaceBetween: 30,
            containerClass: 'swiper-container__app'
          }
    
        return (
            <Observer {...options}>
                <div className="block app gsap">
                    <div className="container remove--relative">
                        {
                            detailItems.map( (detail) => { 
                                return <div key={ shortid.generate() } className="row">
                                            <div className=" col-12 col-lg-6 app__left">
                                                <Swiper {...params}>
                                                    {
                                                        detail.images.map(( image ) => {
                                                            return  <div key={ shortid.generate() } className="swiper-slide">
                                                                        <picture className="gsap-timeline" data-alt="mobile-app">
                                                                            <source srcSet={ image.fields.file.url } type="image/webp" />
                                                                        </picture>
                                                                    </div>
                                                        })
                                                    }
                                                </Swiper>
                                            </div>
                                            <div className="col-12 col-lg-6 app__right">
                                                <div className="absolute-center app__text section--padding">
                                                    <p className="app__text_title gsap-timeline">{ detail.description }</p>
                                                    <p className="app__text_description gsap-timeline">{ detail.title }</p>
                                                </div>
                                            </div>
                                        </div>
                            })
                        }
                    </div>
                </div>
            </Observer>
        )
    }
});

export default React.memo(SwipperImageText);