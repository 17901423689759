import * as React from "react";

const FuelTooltip = function FuelTooltip({ description }) {
    return (
        <div className="requirements__tooltip">
            <svg xmlns="http://www.w3.org/2000/svg" width="30.721" height="30.721" viewBox="0 0 30.721 30.721">
            <g opacity="0.496">
                <path d="M15.361,0A15.361,15.361,0,1,0,30.721,15.361,15.378,15.378,0,0,0,15.361,0Zm0,28.8A13.44,13.44,0,1,1,28.8,15.361,13.455,13.455,0,0,1,15.361,28.8Z" fill="#124657"/>
                <path d="M256.046,113.127a1.44,1.44,0,1,1-2.037,0,1.44,1.44,0,0,1,2.037,0" transform="translate(-236.307 -105.025)" fill="#124657"/>
                <path d="M169.2,170.8a.469.469,0,0,1-.01-.472l4.669-8.671a1.929,1.929,0,0,0-1.972-2.824l-4.82.688.272,1.9,4.828-.675-4.669,8.67a2.4,2.4,0,0,0,2.111,3.534h5.274v-1.92h-5.274A.47.47,0,0,1,169.2,170.8Z" transform="translate(-155.68 -147.988)" fill="#124657"/>
            </g>
            </svg>
            <div className="tooltip__item">
                <p className=" tooltip__text text--uppercase">{ description }</p>
            </div>
        </div>
    )
}

export default React.memo(FuelTooltip);