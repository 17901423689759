import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, tweenHeadlineImage } from '../gsap/timeline';
import { Parallax as ParallaxBG } from "react-parallax";
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        tweenHeadlineImage(event.target);
        unobserve();
    }
}

const Quote = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ imageType, quoteItem, pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
    
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "Row" && item == quoteItem) {
                detailItems.push(detail.fields);
            }
        }
    
        return (
            <Observer {...options}>
                <div className="block height">
                    {
                        detailItems.map( (detail) => { 
                            return <div key={ shortid.generate() } className="block post post--aviation post--16-9 gsap">
                                        <ParallaxBG bgImage={ `${detail.images[0].fields.file.url}?w=1680&h=475&fm=${ imageType }&q=70` } strength={350}>
                                            <div className="container height">
                                                <div className="post__items white--items">
                                                    <h2 className="gsap-timeline"> { detail.title }</h2>
                                                    <p className="gsap-timeline">
                                                        { detail.description }
                                                    </p>
                                                </div>
                                            </div>
                                        </ParallaxBG> 
                                    </div>  
                        })   
                    }
                </div>
            </Observer>
        )
    }
})

export default React.memo(Quote);