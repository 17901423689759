
import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, loadImage } from '../gsap/timeline';
import PageDetails from "../components/PageDetails";
import PageDetailsList from "../components/PageDetailsList";
import { asyncComponent } from 'react-async-component';

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        loadImage(event.target);
        unobserve();
    }
}

const StatisticsHome = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ type, name, title, description, pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -15%'
        };
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "Small Detail") {
                detailItems.push(detail.fields);
            }
        }
    
        return (
            <Observer {...options}>
                <div className="block statistic">
                    <div className={ type ? `container` : `container remove--relative` }>
                        <div className="statistic__content gsap">
                            <div className="row remove--side-margins">
                                <div className="statistic__left col-lg-6">
                                    <h4 className="gsap-timeline">{ name }</h4>
                                    <h3 className="animate--title" >
                                        <span>{ title }</span>
                                    </h3>
                                    <p className="statistic__description gsap-timeline">
                                        { description }
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className={ type ? `statistic__right section--padding statistic__right--padding statistic__right--color statistic__right--after gsap` : `statistic__right gsap` }> 
                            {
                                detailItems.map( (detail, i) => { 
                                    return (type === 'list') ? <PageDetailsList key={i} counter={i} title={ detail.title } description={ detail.description }></PageDetailsList>  
                                    : <PageDetails key={i} image={ detail.images[0].fields.file.url } counter={i} title={ detail.title } description={ detail.description }></PageDetails>
                                })
                            }
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
})

export default React.memo(StatisticsHome);