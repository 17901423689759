
import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock } from '../gsap/timeline';
import PageDetailsText from "./PageDetailText";
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        unobserve();
    }
}

const StatisticsText = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ name, title, description, pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "Row" && detailItems.length === 0) {
                detailItems.push(detail.fields);
            }
        }
    
        return (
            <Observer {...options}>
                <div className="block statistic">
                    <div className="container">
                        <div className="statistic__content gsap">
                            <div className="row remove--side-margins">
                                <div className="statistic__left col-lg-6">
                                    <h4 className="gsap-timeline">{ name }</h4>
                                    <h3 className="animate--title" data-rellax-speed="2" data-rellax-max="10" >
                                        <span>{ title }</span>
                                    </h3>
                                    <p className="statistic__description gsap-timeline">
                                        { description }
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="statistic__right section--padding statistic__right--supply statistic__right--after after--supply section--padding gsap">
                        {
                            detailItems.map( (detail) => { 
                            return <PageDetailsText key={ shortid.generate() } title={ detail.title } description={ detail.description }></PageDetailsText>
                            })
                        }
                        
                    </div>
                </div>
            </Observer>
        )
    }
});

export default React.memo(StatisticsText);