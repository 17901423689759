import * as React from "react";
  
const ProcessItem = function ProcessItem({ title, image }) {
    return (
        <div className="col-6 col-lg-3 services__item gsap-timeline">
            <picture data-alt="search-picture">
                <source srcSet={ image.fields.file.url } type="image/svg" />
            </picture>
            <p>{ title }</p>
        </div>
    )
}

export default React.memo(ProcessItem);