import * as React from "react";
import Parallax from 'react-rellax'
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock } from '../gsap/timeline';
import SupplyItem from './SupplyItem';
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        unobserve();
    }
}

export default asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ name, title, description, pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            root: '#scrolling-container',
            rootMargin: '0% 0% -25%'
        };
    
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "Small Detail") {
                detailItems.push(detail.fields);
            }
        }
    
        return (
            <Observer {...options}> 
                <div className="block statistic">
                    <div className="container">
                        <div className="statistic__content gsap">
                            <div className="row remove--side-margins">
                                <div className="statistic__left statistic__left--supply col-lg-6">
                                    <Parallax speed={-0.4}>
                                        <h4 className="gsap-timeline">{ name }</h4>
                                    </Parallax>
                                    <Parallax speed={-0.6}>
                                        <h3 className="animate--title">
                                            <span>{ title }</span>
                                        </h3>
                                    </Parallax>
                                    <Parallax speed={-0.5}>
                                        <p className="statistic__description gsap-timeline">
                                        { description }
                                        </p>
                                    </Parallax>
                                </div>
                            </div>
                        </div>
                        <div className="statistic__right statistic__right--padding statistic__right--supply statistic__right--after after--supply gsap">
                            <div className="statistic__delivery">
                            {
                                detailItems.map( (detail) => { 
                                    return <SupplyItem key={ shortid.generate() } title={ detail.title } description={ detail.description }></SupplyItem>
                                })
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </Observer>
        )
    }
});
