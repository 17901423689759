import * as React from "react";
import FuelTooltip from "./FuelTooltip";
  

const FuelType = function FuelType({ title, description }) {
    return (
        <div className=" col-12 col-lg-6">
            <div className="requirements__item ">
                <h3 className="text--uppercase">{ title }</h3>
                {
                    (description) ? <FuelTooltip description={ description }></FuelTooltip> : null
                }
            </div>
        </div>
    )
}

export default React.memo(FuelType);