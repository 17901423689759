import React, { Component } from 'react';
import ReactGA from 'react-ga';

import './App.css';
import './fonts.css';

import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { play, exit } from './gsap';

import Home from './pages/Home';
import Error from './pages/Error';
import Trading from './pages/Trading';
import Supply from './pages/Supply';
import Aviation from './pages/Aviation';
import Military from './pages/Military';
import Retail from './pages/Retail';
import Quality from './pages/Quality';
import Contact from './pages/Contact';
import Landing from './pages/Landing';
// import Location from './pages/Location';
import QahssePolicy from './pages/Qahsse-policy';
import Ad from './components/Ad';

import Navbar from './components/Navbar';

import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { RoomContext } from './context';

class App extends Component {

  static contextType = RoomContext;

    windowScroll() {
        var body = document.body,
        timer;

    window.addEventListener('scroll', function() {
      clearTimeout(timer);
      if(!body.classList.contains('disable-hover')) {
        body.classList.add('disable-hover')
      }
      
      timer = setTimeout(function(){
        body.classList.remove('disable-hover')
      },500);
    }, false);
  }
  
  
  render() {

    ReactGA.initialize('UA-151106962-1');

    const routeComponents = {
      'aviation': Aviation,
      'aviacion': Aviation,
      'qahsse-policy': QahssePolicy,
      'politika-kshsm': QahssePolicy,
      'landing': Landing,
      'landingalbanian': Landing,
      'contact-us': Contact,
      'kontakt': Contact,
      'home': Home,
      'ballina': Home,
      'quality': Quality,
      'kualiteti': Quality,
      'trading': Trading,
      'tregetimi': Trading,
      'retail': Retail,
      'retail-al': Retail,
      'military-services': Military,
      'sherbimet-ushtarake': Military,
      'supply': Supply,
      'furnizimi': Supply,
      // location: Location,
      // lokacioni: Location,
    };

    this.windowScroll();

    const { contentfulItems } = this.context;

    const detailItems = [];

    if(contentfulItems) {
      for (let item of Object.keys(contentfulItems)) {
          let detail = {
            slug: contentfulItems[item].fields.slug,
            template: routeComponents[contentfulItems[item].fields.slug]
          }
          detailItems.push(detail);
      }
    }

    let pathname = window.location.pathname;
    const isAlbanian = pathname.includes('/sq') ? true : false;

    return (
      <BrowserRouter>
        <Navbar/>
        <Route render={({ location }) => {
          const { pathname, key } = location;

          return (
            <TransitionGroup component={null}>
              <CSSTransition
                key={key}
                appear={true}
                onEnter={(node, appears) => play(pathname, node, appears)}
                onExit={(node, appears) => exit(node, appears)}
                timeout={{enter: 750, exit: 700}}
              >

              <Switch location={location}>
                  <Route exact path={ isAlbanian ? '/sq' : '/' } component={ Home } />
                  {
                    detailItems.map( (detail, i) => { 
                      return <Route key={i} path={ isAlbanian ? `/sq/${ detail.slug }/` : `/${ detail.slug }/`} component={ detail.template }/>;
                    })
                  }
                  <Route path='/ad/' component={ Ad }/>
                  <Route component={ Error }/>;
              </Switch>

              </CSSTransition>
            </TransitionGroup>
          );
        }}/>
      </BrowserRouter>
    );
  }
}

export default App;

