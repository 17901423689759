import React, { PureComponent } from 'react'
import { NavLink, Link } from 'react-router-dom';

import Logo from '../assets/images/logo.svg';
import Facebook from '../assets/images/header-facebook.svg';
import Instagram from '../assets/images/header-instagram.svg';

import { RoomContext } from '../context';
import shortid from 'shortid';

const Navbar = class Navbar extends PureComponent {
    static contextType = RoomContext;

    state = {
        isOpen: false
    }

    handleToggle = () => {
        const windowWidth = window.innerWidth;
        const body = document.querySelector('html');

        if ( windowWidth > 768 ) {
            body.style.overflow = 'auto';
            this.setState({ isOpen: false });
            return;
        }

        this.setState({ isOpen: !this.state.isOpen });

        if(!this.state.isOpen) {
            body.style.overflow = 'hidden';
        }else {
            body.style.overflow = 'auto';
        }
    }

    logoClose = () => {
        const windowWidth = window.innerWidth;
        const body = document.querySelector('html');

        if ( windowWidth > 768 ) {
            body.style.overflow = 'auto';
            this.setState({ isOpen: false });
            return;
        }

        
        if(this.state.isOpen) {
            this.setState({ isOpen: !this.state.isOpen });
            body.style.overflow = 'auto';
        }
    }
    
    
    render() {
        const { contentfulItems } = this.context;

        const detailItems = [];

        if(contentfulItems) {
            for (let item of Object.keys(contentfulItems)) {
                if(contentfulItems[item].fields.showInMenu) {
                    let detail = {
                        slug: contentfulItems[item].fields.slug,
                        title: contentfulItems[item].fields.name,
                        order: contentfulItems[item].fields.order
                    }
                    detailItems.push(detail);
                }
            }
        }

        detailItems.sort((a, b) => a.order - b.order);

        let pathname = window.location.pathname;
        const isAlbanian = pathname.includes('/sq') ? true : false;

        return (
            <div className={ this.state.isOpen ? `menu__open` : null}>
                <header className="main__header o-hidden">
                    <div className="container">
                        <div className="navbar">
                            <Link className="main__logo"  to={ isAlbanian ? '/sq' : '/' } onClick={ this.logoClose }>
                                <picture data-alt="Gentrit">
                                    <source srcSet={Logo} type="image/svg" />
                                    <img src={Logo} alt=''/>
                                </picture>
                            </Link>
                            <div className="responsive__menu" onClick={ this.handleToggle }>
                                <div className="line"></div>
                                <div className="line"></div>
                                <div className="line"></div>
                            </div>
                            <div className={ this.state.isOpen ? `main__navigation visible__navigation` : `main__navigation`}>
                                <ul className="menu">
                                {
                                    detailItems.map( (detail) => { 
                                      return    <li key={ shortid.generate() }>
                                                    <NavLink exact to={ isAlbanian ? `/sq/${detail.slug}` : detail.slug } onClick={ this.handleToggle }>
                                                        { detail.title }
                                                    </NavLink>
                                                </li>
                                    })
                                }      
                                    <li className="visible-on-mobile">
                                        <NavLink exact to={ isAlbanian ? `/sq/kontakt/` : `contact-us` } onClick={ this.handleToggle }>
                                            { isAlbanian ? `Kontakt` : `Contact Us` }
                                        </NavLink>
                                    </li>                                                                                       
                                </ul>
                                <div className="header__social__media">
                                    <ul>
                                        <li>
                                            <a href="https://www.facebook.com/exfisquality/">
                                                <picture className="facebook__logo" data-alt="facebook">
                                                    <img src={ Facebook } alt="Facebook"/>
                                                </picture>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://www.instagram.com/exfis.official/?igshid=168lumdns45p6">
                                                <picture className="instagram__logo" data-alt="instagram">
                                                    <img src={ Instagram } alt="Instagram"/>
                                                </picture>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="main__border border--mobile">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                </header>
            </div>
        )
    }
}

export default React.memo(Navbar);