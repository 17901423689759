import React from "react";
import MainLogo from '../assets/images/logo.svg';
import { asyncComponent } from 'react-async-component';

const Loading = asyncComponent({
    serverMode: 'defer',
    resolve: () => () => {
        return (
            <div className="loading">
                <div className="loading-logo">
                    <img src={ MainLogo } alt="exfis logo" />
                </div>
            </div>
        )
    }
});

export default React.memo(Loading);