import { TimelineMax, Sine } from "gsap";

export const tweenMaxAnimateOnce = (target, duration) => {
  const gsapTimelineElements = target.querySelectorAll(".gsap-timeline");

  setTimeout(() => {
    if (gsapTimelineElements && gsapTimelineElements.length > 0) {
      const gsapTimline = new TimelineMax();

      gsapTimelineElements.forEach((gElement) => {
        gsapTimline.fromTo(
          gElement,
          0.3,
          {
            opacity: 0,
            x: 0,
            y: 20,
            scaleY: 1.1,
            skewY: 1,
            ease: Sine.easeIn,
            delay: -0.05,
          },
          {
            opacity: 1,
            x: 0,
            y: 0,
            scaleY: 1,
            skewY: 0,
            ease: Sine.easeOut,
            delay: -0.05,
          }
        );
      });
    }
  }, duration);
};

export const gsapBlock = (target) => {
  setTimeout(() => {
    target && target.classList.add("block--active");
  }, 300);
};

export const tweenHeadlineImage = (element) => {
  setTimeout(() => {
    if (element) {
      let el = element.querySelector(".react-parallax-bgimage");
      el && el.classList.add("animate");
    }
  }, 550);
};

export const loadImage = (element) => {
  const imageElements = element.querySelectorAll("picture");

  if (!imageElements && imageElements.length > 0) {
    return;
  }

  imageElements.forEach(function (image) {
    const pictureElements = image.childNodes;
    const imageInstance = new Image();
    pictureElements.forEach((element) => {
      imageInstance.src = element.attributes[0].value;
    });
    imageInstance.alt = image.getAttribute("data-alt");

    image.append(imageInstance);
  });
};

export const checkBrowser = () => {
  var isSafari =
    navigator.vendor &&
    navigator.vendor.indexOf("Apple") > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("CriOS") == -1 &&
    navigator.userAgent.indexOf("FxiOS") == -1;
  return isSafari;
};
