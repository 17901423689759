import { TimelineMax, Sine } from 'gsap';

const getDefaultTimeline = (node, delay) => {
  const timeline = new TimelineMax();

  timeline.fromTo(node, delay, { opacity: 0, ease: Sine.easeIn, delay: -0.05 }, 
                { opacity: 1, ease: Sine.easeOut, delay: -0.05 });     
                
  setTimeout( () => {
    node.classList.add('block--active');
  }, delay);              

  return timeline;
}

const getHomeTimeline = (node, delay) => {
  const timeline = new TimelineMax();

  timeline.fromTo(node, delay, { opacity: 0, ease: Sine.easeIn, delay: -0.05 }, 
    { opacity: 1, ease: Sine.easeOut, delay: -0.05 });

  setTimeout( () => {
    node.classList.add('block--active');
  }, delay);    

  return timeline;
}

export const play = (pathname, node, appears) => {
  const delay = appears ? 0 : 0.5;

  let pageClass = pathname.replace(/\//g,'');
  const bodyClasses= document.querySelector('body').classList; 

  bodyClasses.forEach(element => {
    bodyClasses.remove(element);
  });
  
  if(pageClass) {
    bodyClasses.add(pageClass);
  }

  if (pathname === '/') {
    getHomeTimeline(node, delay);
  } else {
    getDefaultTimeline(node, delay);
  } 
  // window.loadPromise.then(() => requestAnimationFrame(() => timeline.play()))
}

export const exit = (node) => {
  const timeline = new TimelineMax();

  timeline.to(node, 3, { autoAlpha: 0, opacity: 0, ease: Sine.easeOut });
  timeline.play();
}
