import React, { PureComponent } from "react";
import Headline from "./Headline";
import Footer from "./Footer";
import ReactGA from 'react-ga';

import MobileAppImage from '../assets/images/mobile-app.jpg';
  
const Ad = class Ad extends PureComponent {

    getMobileOperatingSystem() {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
          if (/windows phone/i.test(userAgent)) {
              return "Windows Phone";
          }
          if (/android/i.test(userAgent)) {
              return "Android";
          }
          if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
              return "iOS";
          }
          return "unknown";
    }

    constructor(props) {
        super(props);
        const mobileOs = this.getMobileOperatingSystem();

        ReactGA.pageview('/ad');

        if (mobileOs === 'iOS') {
            window.location.href = 'https://apps.apple.com/us/app/exfis/id1476712703';
        }else if (mobileOs === 'Android') {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.frakton.ex_fis&hl=en_IN';
        }else {
            window.location.href = 'https://exfis.com/sq/landingalbanian';
        }
    }

    render() {
        const title = 'Do të ridrejtoheni në aplikacionin EXFIS...';
    
        return (
            <React.Fragment>
                <Headline title={ title } image={ MobileAppImage }></Headline>
                <Footer></Footer>
            </React.Fragment>
        )
    }
}   

export default React.memo(Ad);