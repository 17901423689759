import * as React from "react";
  
export default function SupplyItem({ title, description }) {
    return (
        <>
            <h3 className="gsap-timeline">{ title }</h3>
            <h2 className="gsap-timeline">
                <span>{ description }</span>
            </h2>
        </>
    )
}