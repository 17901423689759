import * as React from "react";
  
const ProcessIdeologyItem = function ProcessIdeologyItem({ title, image }) {
    return (
        <div className="swiper-slide">
            <div className="gsap-timeline qualities__item dark--blue-color">
                <div className="qualities__items">
                    <picture data-alt="secure">
                        <source srcSet={ image.fields.file.url } type="image/svg" />
                    </picture>
                    <p>{ title }</p>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ProcessIdeologyItem)