import * as React from "react";
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock } from '../gsap/timeline';
import { asyncComponent } from 'react-async-component';
import shortid from "shortid";

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        unobserve();
    }
}

const ImageInfo = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ imageType, pageClass, pageDetails }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% -25%'
        };
    
        const detailItems = [];
    
        for (let item of Object.keys(pageDetails)) {
            let detail = pageDetails[item];
            if(detail.fields.position === "ExtendedRow") {
                detailItems.push(detail.fields);
            }
        }
    
        return (
            <Observer {...options}>
                <div className={`block half gsap ${ pageClass }`}>
                {
                    detailItems.map( (detail) => { 
                        return <div key={ shortid.generate() } className="container remove--relative">
                                    <div className="half__left" style={{ backgroundImage: `url(${ detail.images[0].fields.file.url }?w=1680&h=475&fm=${imageType}&q=80)`}}>
                                    </div>
                                    <div className="half__right section--padding">
                                            <p className="gsap-timeline">
                                                { detail.description }
                                            </p>
                                    </div>
                                </div>    
                        })
                    }
                </div>        
            </Observer>
        )
    }
});

export default React.memo(ImageInfo);