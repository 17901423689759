import React from 'react'
import Observer from '@researchgate/react-intersection-observer';
import { tweenMaxAnimateOnce, gsapBlock, tweenHeadlineImage } from '../gsap/timeline';
import { Parallax as ParallaxBG } from "react-parallax";
import Parallax from 'react-rellax';
import { asyncComponent } from 'react-async-component';

function handleIntersection(event, unobserve) {
    if(event.isIntersecting) {
        gsapBlock(event.target);
        tweenMaxAnimateOnce(event.target)
        tweenHeadlineImage(event.target)
        unobserve();
    }
}

const Headline = asyncComponent({
    serverMode: 'defer',
    resolve: () => ({ title, image }) => {
        const options = {
            onChange: handleIntersection,
            rootMargin: '0% 0% 0%'
        };

        return (
            <Observer {...options}>
                <div className="block main main__image">
                    <ParallaxBG bgImage={ image } strength={350}>
                        <div className="container">
                            <div className="main__info">
                                <Parallax speed={ 0.5 }>
                                    <h1 className="main__title animate--title white--font">
                                        <span>{ title }</span>
                                    </h1>
                                </Parallax>
                            </div>
                        </div>
                    </ParallaxBG>
                    <div className="main__border">
                        <span className="gsap-timeline"></span>
                        <span className="gsap-timeline"></span>
                        <span className="gsap-timeline"></span>
                        <span className="gsap-timeline"></span>
                        <span className="gsap-timeline"></span>
                    </div>
                </div>
            </Observer>
        )
    }
})

export default React.memo(Headline);